import { T } from "@/locales";

export default [
  {
    title: T("序号"),
    dataIndex: "index",
    key: "index",
    customRender: (value, item, index) => {
      return index + 1;
    },
  },
  {
    title: T("创建时间"),
    dataIndex: "create_time",
  },
  {
    title: T("产品名称"),
    dataIndex: "material_name",
    ellipsis: true,
  },
  {
    title: T("产品编号"),
    dataIndex: "material_number",
  },
  {
    title: T("仓库名称"),
    dataIndex: "warehouse_name",
  },
  {
    title: T("仓库编号"),
    dataIndex: "warehouse_number",
  },
  {
    title: T("流水类型"),
    dataIndex: "type_display",
  },
  {
    title: T("操作前数量"),
    dataIndex: "quantity_before",
  },
  {
    title: T("操作数量"),
    dataIndex: "quantity_change",
  },
  {
    title: T("现有库存量"),
    dataIndex: "quantity_after",
  },
  {
    title: T("单据编号"),
    dataIndex: "order_number",
  },
  {
    title: T("创建人"),
    dataIndex: "creator_name",
  },
];
