import request from "@/utils/request";

// InventoryFlow
export function inventoryFlowList(params) {
  return request({ url: `/inventory_flows/`, method: "get", params });
}

export function inventoryFlowExportList(params) {
  return request({ url: `/inventory_flows/export/`, method: "get", params, responseType: "blob" });
}

// LocationFlow
export function locationFlowList(params) {
  return request({ url: `/location_flows/`, method: "get", params });
}

export function locationFlowExport(params) {
  return request({
    url: '/location_flows/export/',
    responseType: 'blob',
    method: 'get',
    params,
  })
}